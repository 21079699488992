@import "~bootstrap/scss/bootstrap";

$template-bg-color: #212121;
$template-level1-color: rgba(26, 26, 26, 1);

:root{
  --bg-color: #121212;
  --lastUpdated-color: white;
  --lvl2-color: rgba(26, 26, 26, 1);
  --nav-bg-color: rgba(26, 26, 26, 1);
  --accordion-color: rgba(26, 26, 26, 1);
  --navbar-brand: #34E82A;
  --url-button-hover-bg: #34E82A;
  --url-button-font-color: #34E82A;
  --url-button-bg: none;
  --url-button-hover-color: white;
  --service-name-color: white;
  --acordion-expanded-color: rgba(38, 37, 37, 1);
  --accordion-button-hover: rgba(38, 37, 37, 1);
  --font-color: white;
  --statusColor: #34E82A;
  --history-list-group: rgba(26, 26, 26, 1);
  --history-hover: rgba(26, 26, 26, 1);
  --main-status-color: white;
  --accordion-box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.5);
  --accordion-div-box-shadow: none;
  --accordion-border: 0 0 0 0;
  --d2l-branding-primary-color: green;
}

.btn-outline-success{
  border-color: var(--url-button-hover-bg);
  color: var(--url-button-font-color);
  background-color: var(--url-button-bg);
}

.btn-outline-success:hover{
  background-color: var(--url-button-hover-bg);
  color: var(--url-button-hover-color);
}

.historyImpactedProductsLabel
{
  color: var(--font-color);
  font-size: 24px;
}

.historyUpdateLabel
{
  color: var(--font-color);
  font-size: 24px;
}

.historyEndDate
{
  color: var(--font-color);
}

.lastUpdatedP{
  color: var(--lastUpdated-color);
}

.historyUpdateNote
{
  font-size: 22px;
  margin-bottom: 0;
  color: var(--font-color);
}


.container-md, .container-sm, .container {
  max-width: 1000px;
}

.historyUpdateDate
{
  font-size: 18px;
  margin-bottom: 0;
  color: var(--font-color);
}


.list-group  {
  background-color: var(--history-list-group);
  color: var(--font-color);
  
  h2{
    color: var(--font-color);
  }
  h3
  {
    color: var(--font-color);
  }
}

.list-group-item  {
  background-color: transparent ;
  margin-bottom: 5;
  border: none;
  color: var(--font-color);
}

body {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

html body {
  background-color: var(--bg-color);
  -webkit-transition: margin .1s ease;
  -moz-transition: margin .1s ease;
  -o-transition: margin .1s ease;
  transition: margin .1s ease;
}

.searchInputDiv
{
  margin-top: 35px;
}
.Pclassleft
{
  margin-left: 12px;
}

.d2lServiceStatus {
  color: var(--font-color);
}

.loadingSpinner {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}



.lineSeperator{
  background-color: var(--font-color);
}

.dark-div {
  background-color: var(--bg-color);
  padding-top: 20;
  padding-bottom:0;
  color: var(--main-status-color)
}


.dot-div{
  display: inline-block;
  padding-right: 10px;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: var(--statusColor);
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0px 0px 10px 1px var(--statusColor);
  vertical-align:middle;
}

.timeline {
  max-width: 1000px;
  background-color: var(--bg-color);
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  display: table;
  margin: 0 auto;
  
  ul {
    list-style-type: none;
    border-left: 2px solid #094a68;
    padding: 10px 5px;
    
    li {
      padding: 20px 20px;
      position: relative;
      cursor: pointer;
      transition: .5s;
      
      span {
        display: inline-block;
        background-color: #1685b8;
        border-radius: 25px;
        padding: 2px 5px;
        font-size: 12px;
        text-align: center;
      }
      
      .content {
        text-align: left;
        h2{
          font-size: 24px;
          padding-top: 5px;
          color: white;
        }
        h3 {
          font-size: 20px;
          padding-top: 5px;
        }
      }
      
      &:before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        background-color: #34ace0;
        border-radius: 50%;
        left: -11px;
        top: 28px;
        transition: .5s;
      }
    }
  }
}

.currentUrlSpan{
  display: inline-block;
  white-space:nowrap;
  color: var(--font-color);
  font-size: 16px;
}

.accordion {
  padding: 0px 0px;
  transition-duration: 0s;
  transition: 0s;
  border-radius: 50px;
  border-width: 0 0 0 0;
  box-shadow: var(--accordion-box-shadow);
  justify-content: center;
}

.accordion-item {
  border-width: 0 0 0 0;
  background-color: var(--accordion-color);
  color: var(--font-color);
}

.accordion-body {
  background-color: var(--accordion-color);
  transition-duration: 0s;
  border-width: 0 0 0 0;
  padding-left: 10 0 0 10;
}

.accordionButtonDiv
{
  width: 100%;
}

.serviceNames{
  width: 70%;
  float: left;
  text-align: left;
  font-Size: 16px;
}

.serviceName{
  color: var(--font-color);
  font-Size: 16px;
}

.accordion-button:not(.collapsed) {
  color: black;
}

.accordion-button::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button {
  display: flex;
  background-color: var(--accordion-color);
  min-width: 100%;
  box-shadow: none;
  border-width: var(--accordion-border);
  align-items: center;
  height: 5vh;
  padding: 0px 10px 0px 10px;
  perspective: 500px;
  
  &[aria-expanded="true"] {
    background-color: var(--acordion-expanded-color);
    border-width: 0 0 0 0;
  }
  
  &:hover {
    background-color: var(--accordion-button-hover);
  }
}

.status {
  width: 800px;
  color: var(--font-color);
  padding: 5px 5px 5px 5px;
  
  ul {
    list-style-type: none;
    padding: 10px 5px;
    background-color:  var(--bg-color);
    border-width: 0 0 0 0;
    padding: 5px 10px 5px 10px;
    position: relative;
    cursor: pointer;
    transition: 0s;
    background-color:  var(--bg-color);
    border-width: 0 0 0 0;
  }
  
  li {
    padding: 0px 10px 2px 10px;
    position: relative;
    cursor: pointer;
    transition: 0s;
    background-color:  var(--bg-color);
  }
}

.statusSpan {
  display: inline-block;
  box-shadow: 0px 0px 3px .1px var(--statusColor);
  background-color: var(--statusColor);
  border-radius: 25px;
  padding: 0px 10px;
  text-align: center;
  float: right;
  font-size: 16px;
}

.updatedTimeSpan {
  display: inline-block;
  text-align: center;
}

.status ul li {
  span {
    display: inline-block;
    background-color: var(--statusColor);
    border-radius: 25px;
    padding: 0px 10px;
    text-align: center;
    float: right;
    font-size: 16px;
  }
  
  .content {
    h3 {
      color: #34ace0;
      font-size: 17px;
      padding-top: 0px;
    }
    
    p {
      color: black;
      padding: 0px 0px 0px 0px;
      font-size: 15px;
    }
  }
  
  &:before {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    background-color: var(--statusColor);
    box-shadow: 0px 0px 10px 2px var(--statusColor);
    border-radius: 50%;
    left: -11px;
    top: 10px;
    transition: .5s;
  }
}

.clientNavDiv {
  text-align: center;
  width: 100vw;
  max-width:100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 40px;
  min-height: 50px;
}

.spaced-button:first-child {
  margin-right: 10px; /* Adds 10px space to the right of the first button */
}

.spaced-button:last-child {
  margin-left: 10px; /* Adds 10px space to the left of the last button */
}

.pageDiv {
  min-width: 100vw;
}

.mainDiv {
  width: 100vw;
  justify-content: center;
}

.dividerDiv{
  padding-left: 20px;
}

.firstIconDiv{
  padding-left: 20px;
  padding-right:20px;
}

.lastIconDiv{
  padding-right:40px;
}

.searchIconDiv{
  display: none;
}

.serviceStates{
  width: 30%;
  float: right;
  text-align: right;
}
.serviceStates1{
  width: 30%;
  margin-right:5px;
  float: right;
  text-align: right;
}

.accordionDiv {
  max-width: 1000px;
  margin: 0 auto;
  padding-left:40px;
  padding-right:40px;
}

.timeline {
  list-group list-group-item{
    text-align:left;
  }
}

.historyDate{
  font-size: 28px;
  display: inline;
  color: #34ace0;
}

.status {
  width: 100%;
  padding: 5px 5px 5px 5px;
  border-width: 0 0 0 0;
  background-color: var(--accordion-color);
  
  ul li .content h3 {
    font-size: 20px;
  }
}
.pageDivbottom {
  display: flex;
  align-items: flex-end;
  justify-content: center
}
.element1 {
  margin-right: 10px;
}
.element2 {
  margin-left: 10px;
}


/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


@media screen and (min-width: 0px) and (max-width: 670px) {
  
  .accordionDiv {
    padding-left: 0px;
    padding-right: 30px;
  }
  
  .serviceNames{
    width: 100%;
  }
  
  .serviceStates{
    display: none;
    width: 0%;
  }
  
  .searchInputDiv{
    display:none;
  }
  
  .searchIconDiv{
    display: block;
  }
  
}


  /* DisplayGraph.css */
  .mainDiv1 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
    overflow: hidden;
  }


  .noteDiv {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    overflow: hidden;
  }

 
   .chartWrapper {
    background-color: white;
    padding: 50px;
    box-shadow: 0px 0px 10px rgba(0,0,0,.5);
    position: absolute;
   }

   .chartWrapper1 {
    background-color: #212121;
    padding: 50px;
    box-shadow: 0px 0px 10px rgba(0,0,0,.5);
    position: absolute;
   }
   
   .downloadButton {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #00FF00;
    border-radius: 50%;
    padding: 1px;
    border: none;
    cursor: pointer;
    z-index: 1000;
   }

   .downloadButtonDark {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #0474c4;
    border-radius: 50%;
    padding: 1px;
    border: none;
    cursor: pointer;
    z-index: 1000;
   }

   .downloadCSVButton {
    position: absolute;
    top: 20px;
    right: 60px;
    background: #00FF00;
    border-radius: 50%;
    padding: 2px;
    border: none;
    cursor: pointer;
    z-index: 1000;
   }
   .downloadCSVButtonDark {
    position: absolute;
    top: 20px;
    right: 60px;
    background: #0474c4;
    border-radius: 50%;
    padding: 2px;
    border: none;
    cursor: pointer;
    z-index: 1000;
   }

   .requestButton {
    position: absolute;
    top: 20px;
    right: 110px;
    padding: 2px;
    border: none;
    cursor: pointer;
    z-index: 1000;
   }
   .requestButtonDark {
    position: absolute;
    top: 20px;
    right: 110px;
    padding: 2px;
    border: none;
    cursor: pointer;
    z-index: 1000;
   }
   .mainDiv2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    overflow: hidden;
  }

  .table-container {
    width: 90%;
    max-height: 300px; 
    overflow-y: auto;
    margin: 0 auto;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 15px;
    text-align: center;
    table-layout: fixed;
  }

  .table th, .table td {
    padding: 12px 15px;
    border: 1px solid black;
  }

  .table thead {
    background-color: white;
    position: sticky;
    top: 0;
  }

  .table thead th {
    color: #0474c4;
  }

  .table tbody td {
    background-color: white;
    border-top: none;
  }
// Drak mode table css
  .table-dark {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 15px;
    text-align: center;
    table-layout: fixed;
    color: white; 
    background-color: #212121;
  }

  .table-dark th, .table-dark td {
    padding: 12px 15px;
    border: 1px solid rgb(126, 126, 126); 
  }

  .table-dark thead {
    background-color:  #212121; 
    position: sticky;
    top: 0;
  }

  .table-dark thead th {
    color:  #00FF00; 
  }


  .table-dark tbody td {
    background-color:  #212121; 
    border-top: none;
  }

  //table heading class 
  .table-heading {
    color: #0474c4;
    text-align: center;
    font-size: 20px;
    font-weight: bold;

  }

  .table-heading-dark {
    color: #00FF00;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }


  .rainbow-text {
    color: purple;
    font-weight: bold;
    font-size: 1.5em;
  }